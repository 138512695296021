import React from "react";
import { graphql } from "gatsby";

import { Page } from "engine/pageContext";

import Layout from "../components/layout";
import Contacts from "../components/contacts";

const ContactPage = ({ pageContext: { lang }, data: { localize } }) => {
  return (
    <Page pageContext={{ lang, l: localize }}>
      <Layout
        title="Skontaktuj się z agencją pracy LB w Otwocku"
        description="Specjaliści naszej agencji pracy szybko i sprawnie rozwiążą Twój problem"
      >
        <div role="main" className="main">
          <Contacts />
        </div>
      </Layout>
    </Page>
  );
};

export default ContactPage;

export const pageQuery = graphql`
  query ($lang: String!) {
    localize(lang: $lang)
  }
`;
